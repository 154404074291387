import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/auth';

const firebaseTest = process.env.REACT_APP_TESTING === 'TRUE' ?? false; //! Make sure its false on production release!

if (firebaseTest) {	
	console.warn("!!! Firebase connected to test environment !!!");
}
const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
};

const testConfig = {
	apiKey: process.env.REACT_APP_TEST_API_KEY,
	authDomain: process.env.REACT_APP_TEST_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_TEST_DATABASE_URL,
	projectId: process.env.REACT_APP_TEST_PROJECT_ID,
};

// const dev_config = {
// 	apiKey: process.env.REACT_APP_API_KEY,
// 	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
// 	databaseURL: 'http://localhost:9000/?ns=mussila-80632',
// 	projectId: process.env.REACT_APP_PROJECT_ID,
// }

// eslint-disable-next-line no-restricted-globals
firebase.initializeApp(firebaseTest ? testConfig : config);

export const authentication = firebase.auth();
export const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
export default firebase;
