// firebase.ts not firebase package
import {
	accountInterface,
	AccountsProps,
	SkolonProps,
	updateAccountInterface,
} from '../types/accounts';
import { GroupInfoInterface, SkolonGroupsProps } from '../types/groups';
import { StudentInfoInterface } from '../types/students';
import firebase from './firebase';
import { getStripe, stripeTest } from '../libs/getStripe';
import { GroupInfo, ITeacherInvite } from '../types/teachers';
import { localize as l } from '../libs/Localization';

// AUTH FUNCTIONS

export const sendResetPassEmail = async (email: string) => {
	return await firebase
		.auth()
		.sendPasswordResetEmail(email)
		.then(() => {
			return { error: null, success: l['Reset Password email sent'] };
		})
		.catch(() => {
			return { success: null, error: l['Issue sending Reset Password email'] };
		});
};

// CLOUD FUNCTION

export const callCloudFunction = async (name: string) => {
	return firebase.functions().httpsCallable(name);
};

// GETTERS

export const getUser = async (userID: string) => {
	return (await firebase.database().ref('users').child(userID).once('value')).val();
};

export const getUserByEmail = async (email: string) => {
	return (await firebase.database().ref('users').orderByChild('email').equalTo(email).once('value')).val();
};

export const getUserPurchases = async (userID: string) => {
	const hasGiftCardMusic: string | undefined = (
		await firebase.database().ref('users').child(userID).child('giftcardExpireDate').once('value')
	).val();
	const hasGiftCardWordPlay: string | undefined = (
		await firebase.database().ref('users').child(userID).child('giftcardExpireDate_wordplay').once('value')
	).val();
	const hasIAPMusic: boolean | undefined = (
		await firebase
			.database()
			.ref('users')
			.child(userID)
			.child('userData')
			.child('userPurchasedPremiumIAP')
			.once('value')
	).val();
	const hasIAPWordPlay: boolean | undefined = (
		await firebase
			.database()
			.ref('users')
			.child(userID)
			.child('userData_wordplay')
			.child('userPurchasedPremiumIAP')
			.once('value')
	).val();
	// if any of the above are true, then the user has a premium account
	let hasMusic = false,
		hasWordPlay = false,
		hasValidGiftCardMusic = false,
		hasValidGiftCardWordPlay = false;
	const today = new Date();
	const weekFromNow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
	if (hasGiftCardMusic) {
		const giftCardExpireDate = new Date(hasGiftCardMusic);
		if (giftCardExpireDate > weekFromNow) {
			// if the gift card is valid for more than a week, then the user has a premium account
			hasValidGiftCardMusic = true;
		}
	}
	if (hasGiftCardWordPlay) {
		const giftCardExpireDate = new Date(hasGiftCardWordPlay);
		if (giftCardExpireDate > weekFromNow) {
			// if the gift card is valid for more than a week, then the user has a premium account
			hasValidGiftCardWordPlay = true;
		}
	}
	if (hasValidGiftCardMusic || hasIAPMusic) {
		hasMusic = true;
	}
	if (hasValidGiftCardWordPlay || hasIAPWordPlay) {
		hasWordPlay = true;
	}
	return { hasMusic: hasMusic, hasWordPlay: hasWordPlay };
};

export const getSkolonUserMussilaData = async (skolonUserID: string) => {
	const getSkolonUserMussilaDataFunc = await callCloudFunction('getSkolonUserMussilaData');
	return await getSkolonUserMussilaDataFunc({ skolonUserID });
};

export const getUserType = async (session: firebase.User) => {
	const userId = session.uid;
	const userData: accountInterface = await (await firebase.database().ref('users').child(userId).once('value')).val();
	const accountType = userData?.accountType;
	const userType = userData?.userType;
	const isFamilyAccount = userData?.isFamilyAccount;
	const isSchoolAccount = userData?.isSchoolAccount;
	if (await amIAdmin(userId)) return 'mussila-monster';
	if (accountType) return accountType;
	if (isFamilyAccount) return 'family';
	if (isSchoolAccount) return 'school';
	if (userType === 'Teacher') return 'skolon-teacher';
	if (session.isAnonymous) return 'anon';
	return 'family';
};

// export const amIDistrict = async (userID: string) => {
// 	return await (await firebase.database().ref('users').child(userID).child('accountType').once('value')).val() === 'district';
// };

// export const amIFamily = async (userID: string) => {
// 	return await (await firebase.database().ref('users').child(userID).child('isFamilyAccount').once('value')).val();
// };

// export const amISchool = async (userID: string) => {
// 	return await (await firebase.database().ref('users').child(userID).child('isSchoolAccount').once('value')).val();
// };

// export const amITeacher = async (userID: string) => {
// 	return await (await firebase.database().ref('users').child(userID).child('accountType').once('value')).val() === 'teacher';
// };

// export const amISkolonTeacher = async (userID: string) => {
// 	return await (await firebase.database().ref('users').child(userID).child('userType').once('value')).val() === 'Teacher';
// };

export const amIAdmin = async (userID: string) => {
	try {
		return await (await firebase.database().ref('allowed-list').child(userID).once('value')).val();
	} catch {
		return false;
	}
};

export const getIsSchoolAccounts = async () => {
	return await (await firebase.database().ref('users').orderByChild('isSchoolAccount').equalTo(true).once('value')).val();
};

export const getDistrictSchools = async (districtId: string) => {
	const listOfSchools = await (
		await firebase.database().ref('users').child(districtId).child('schools').once('value')
	).val();
	if (!listOfSchools) {
		return {};
	}
	const schoolKeys = Object.keys(listOfSchools);

	let schools: AccountsProps = {};
	// await each school to get their data
	for (let i = 0; i < schoolKeys.length; i++) {
		const school = await getUser(schoolKeys[i]);
		schools[schoolKeys[i]] = school;
	}
	return schools as unknown as AccountsProps;
};

export const getDistricts = async () => {
	return (await firebase.database().ref('users').orderByChild('accountType').equalTo('district').once('value')).val();
};

export const getIsTestAccounts = async () => {
	return (await firebase.database().ref('users').orderByChild('isTestAccount').equalTo(true).once('value')).val();
};

export const getLibraries = async () => {
	return (await firebase.database().ref('users').orderByChild('accountType').equalTo('library').once('value')).val();
};

export const getLibraryName = async (ownerId: string) => {
	return (await firebase.database().ref('users').child(ownerId).child('library').once('value')).val();
};

export const getLibraryApps = async (ownerId: string) => {
	return (await firebase.database().ref('users').child(ownerId).child('apps').once('value')).val();
};

export const setSchoolAccountsAsTest = async (testSchools: AccountsProps) => {
	for (const school in testSchools) {
		if (testSchools[school].isTestAccount) {
			await firebase.database().ref('users').child(school).update({ isTestAccount: null });
		} else {
			await firebase.database().ref('users').child(school).update({ isTestAccount: true });
		}
	}
};

export const getAccountGroups = async (schoolID: string) => {
	return (await firebase.database().ref('users-groups').child(schoolID).once('value')).val();
};

export const getAccountSeats = async (schoolID: string) => {
	return (
		await firebase.database().ref('users').child(schoolID).child('subscription').child('seats').once('value')
	).val();
};

export const getFamilyGroup = async (accountID: string) => {
	return (await firebase.database().ref('users-groups').child(accountID).once('value')).val();
};

export const getCurrentGroup = async (schoolID: string, groupID: string) => {
	return (await firebase.database().ref('users-groups').child(schoolID).child(groupID).once('value')).val();
};

export const getCurrentStudent = async (schoolID: string, studentID: string) => {
	return (await firebase.database().ref('users-oldPlayers').child(schoolID).child(studentID).once('value')).val();
};

export const getCurrentStudentWordPlay = async (schoolID: string, studentID: string) => {
	return (
		await firebase.database().ref('users-oldPlayers-wordplay').child(schoolID).child(studentID).once('value')
	).val();
};

export const getCurrentStudentInfo = async (schoolID: string, groupID: string, studentID: string) => {
	return (
		await firebase.database().ref('users-groupMembers').child(schoolID).child(groupID).child(studentID).once('value')
	).val();
};

export const getSchoolStudentsData = async (schoolID: string) => {
	return (await firebase.database().ref('users-oldPlayers').child(schoolID).once('value')).val();
};

export const getSchoolStudentsDataWordPlay = async (schoolID: string) => {
	return (await firebase.database().ref('users-oldPlayers-wordplay').child(schoolID).once('value')).val();
};

export const getGroupMembers = async (schoolID: string, groupID: string) => {
	return (await firebase.database().ref('users-groupMembers').child(schoolID).child(groupID).once('value')).val();
};

export const getStudentRemoteAccessData = async (playerId: string) => {
	return (await firebase.database().ref('remoteAccess').child(playerId).once('value')).val();
};

export const getGroupRemoteAccessData = async (groupID: string) => {
	return (await firebase.database().ref('remoteAccess').orderByChild('groupId').equalTo(groupID).once('value')).val();
};

export const getSkolonSchools = async () => {
	const schools = await firebase.firestore().collection('skolon').get();
	//! might be a much better way rather than double casting to unknown and then to SkolonProps
	return schools.docs.map((doc) => {
		return { id: doc.id, ...doc.data() };
	}) as unknown as SkolonProps;
};

export const getSkolonGroups = async (schoolID: string) => {
	const groups = await firebase.firestore().collection('skolon').doc(schoolID).collection('groups').get();
	//! might be a much better way rather than double casting to unknown and then to SkolonGroupsProps
	return groups.docs.map((doc) => {
		return { id: doc.id, ...doc.data() };
	}) as unknown as SkolonGroupsProps;
};

// Only searches based on school name.
export const searchAccounts = async (searchTerm: string) => {
	return (
		await firebase
			.database()
			.ref('users')
			.orderByChild('school')
			.startAt(searchTerm)
			.endAt(searchTerm + '\uF7FF')
			.once('value')
	).val();
};

export const getTeacherInvites = async (ownerId: string) => {
	// return object with teacher invites from firestore
	return (await firebase.firestore().collection('teacherInvites').where('ownerId', '==', ownerId).get()).docs.map(
		(doc) => {
			return { ...doc.data() };
		}
	) as unknown as ITeacherInvite[];
};

export const removeGroupFromInvite = async (ownerId: string, groupId: string, inviteCode: string) => {
	const inviteRef = await firebase
		.firestore()
		.collection('teacherInvites')
		.where('inviteCode', '==', inviteCode)
		.where('ownerId', '==', ownerId)
		.limit(1)
		.get();
	inviteRef.docs[0].ref.update({
		groups: firebase.firestore.FieldValue.arrayRemove(groupId),
	});
};

export const removeGroupFromAllInvites = async (ownerId: string, groupId: string) => {
	const inviteRef = await firebase.firestore().collection('teacherInvites').where('ownerId', '==', ownerId).get();
	inviteRef.docs.forEach((doc) => {
		doc.data().groups.forEach((group: { id: string; label: string; value: string }) => {
			if (group.id === groupId) {
				doc.ref.update({
					groups: firebase.firestore.FieldValue.arrayRemove(group),
				});
			}
		});
	});
};

export const reAssignTeacher = async (
	ownerId: string,
	teacherName: string,
	groups: GroupInfo[],
	inviteCode: string,
	teacherId?: string
) => {
	const inviteRef = await firebase
		.firestore()
		.collection('teacherInvites')
		.where('inviteCode', '==', inviteCode)
		.where('ownerId', '==', ownerId)
		.limit(1)
		.get();
	inviteRef.docs[0].ref.update({
		groups,
	});

	if (!teacherId) {
		return;
	}

	await firebase
		.database()
		.ref('users-groups')
		.child(ownerId)
		.once('value')
		.then((snapshot) => {
			const allGroups = snapshot.val();
			for (const group in allGroups) {
				const teacherRef = firebase
					.database()
					.ref('users-groups')
					.child(ownerId)
					.child(group)
					.child('groupAdmins')
					.child(teacherId);
				teacherRef.once('value').then((teacherSnapshot) => {
					if (!groups.find((g) => g.id === group) && teacherSnapshot.exists()) {
						teacherRef.remove();
					}
					if (groups.find((g) => g.id === group) && !teacherSnapshot.exists()) {
						teacherRef.set({ name: teacherName });
					}
				});
			}
		});
};

export const getTeachers = async (ownerId: string) => {
	// return object with teachers from firebase database
	return (await firebase.database().ref('users').child(ownerId).child('teachers').once('value')).val();
};

export const getActiveTeachers = async (ownerId: string) => {
	// return object with teachers from firebase database
	return (
		await firebase
			.database()
			.ref('users')
			.child(ownerId)
			.child('teachers')
			.orderByChild('active')
			.equalTo(true)
			.once('value')
	).val();
};

export const unAssignTeacherFromGroup = async (groupId: string, ownerId: string, teacherId: string) => {
	// remove from firestore invite again for easier access in teacher list
	await firebase
		.firestore()
		.collection('teacherInvites')
		.where('claimedBy', '==', teacherId)
		.where('ownerId', '==', ownerId)
		.limit(1)
		.get()
		.then(async (invite) => {
			if (invite.docs[0]) {
				invite.docs[0].ref.update({
					groups: firebase.firestore.FieldValue.arrayRemove(groupId),
				});
			}
		});
	return await firebase
		.database()
		.ref('users-groups')
		.child(ownerId)
		.child(groupId)
		.child('groupAdmins')
		.child(teacherId)
		.remove();
};

export const assignTeacherToGroup = async (groupId: string, ownerId: string, teacherId: string, name: string) => {
	// add to firestore invite again for easier access in teacher list
	await firebase
		.firestore()
		.collection('teacherInvites')
		.where('claimedBy', '==', teacherId)
		.where('ownerId', '==', ownerId)
		.limit(1)
		.get()
		.then(async (invite) => {
			if (invite.docs[0]) {
				invite.docs[0].ref.update({
					groups: firebase.firestore.FieldValue.arrayUnion(groupId),
				});
			}
		});
	return await firebase
		.database()
		.ref('users-groups')
		.child(ownerId)
		.child(groupId)
		.child('groupAdmins')
		.child(teacherId)
		.set({ name: name });
};

// SETTERS

export const createAccount = async (userID: string, data: accountInterface) => {
	// Error handle necessary?
	await firebase.database().ref('users').child(userID).set(data);
};

export const createUpdateGroup = async (schoolID: string, data: GroupInfoInterface) => {
	// Error handle necessary?
	await firebase.database().ref('users-groups').child(schoolID).child(data.groupId).update(data);
};

export const updateSchoolExpireDates = async (schoolID: string, newDate: string) => {
	const newDates = {
		giftcardExpireDate: newDate,
		giftcardExpireDate_wordplay: newDate,
	};
	await firebase.database().ref('users').child(schoolID).update(newDates);
};

export const updateSchoolSeats = async (schoolID: string, newSeats: number) => {
	if (newSeats < 0 || newSeats > 1000) {
		console.error('Invalid new number of seats');
		return;
	}
	await firebase.database().ref('users').child(schoolID).child('subscription').update({ seats: newSeats });
};

export const updateGroupsInTeacherInvites = async (
	userID: string,
	groupID: string,
	oldGroupName: string,
	newGroupName: string
) => {
	// Updates group name in all invites of a school user to reflect the change
	const oldGroupObject = { id: groupID, label: oldGroupName, value: groupID };
	const newGroupObject = { id: groupID, label: newGroupName, value: groupID };
	await firebase
		.firestore()
		.collection('teacherInvites')
		.where('ownerId', '==', userID)
		.get()
		.then((snapshot) => {
			snapshot.docs.forEach((doc) => {
				doc.ref.update({
					groups: firebase.firestore.FieldValue.arrayRemove(oldGroupObject),
				});
				doc.ref.update({
					groups: firebase.firestore.FieldValue.arrayUnion(newGroupObject),
				});
			});
		});
};

export const createUpdateGroupMember = async (schoolID: string, groupId: string, data: StudentInfoInterface) => {
	// Error handle necessary?
	await firebase.database().ref('users-groupMembers').child(schoolID).child(groupId).child(data.playerId).update(data);
};

export const changeUserSubscription = async (userID: string, newStatus: boolean) => {
	// Error handle necessary?
	await firebase.database().ref('users').child(userID).child('newsletterSubscription').set(newStatus);
};

export const updateUser = async (userID: string, data: updateAccountInterface) => {
	const userRef = firebase.database().ref('users').child(userID);
	await userRef.update(data);
};

export const updateAnonCustomerEmail = async (userID: string, email: string) => {
	// email is saved as anonEmail so that stripe doesn't overwrite it as null
	await firebase.firestore().collection('customers').doc(userID).set({ anonEmail: email, anon: true }, { merge: true });
};

// REMOVERS

export const removeGroup = async (schoolID: string, groupID: string) => {
	let groupMembersRef = firebase.database().ref('users-groupMembers').child(schoolID).child(groupID);
	// Remove all members of group's remote access
	// Remove all members of group
	(await groupMembersRef.once('value')).forEach((student) => {
		const studentID = student.val().playerId;
		let remoteAccessRef = firebase.database().ref('remoteAccess').child(studentID);
		remoteAccessRef.remove();
	});
	await groupMembersRef.remove();
	// Remove group
	let groupRef = firebase.database().ref('users-groups').child(schoolID).child(groupID);
	await groupRef.remove();
	await removeGroupFromAllInvites(schoolID, groupID);
};

export const removeStudent = async (schoolID: string, groupID: string, removeStudent: string) => {
	// Remove member from groupMembers
	let groupMembersRef = firebase
		.database()
		.ref('users-groupMembers')
		.child(schoolID)
		.child(groupID)
		.child(removeStudent);
	await groupMembersRef.remove();
	// Remove member from remoteAccess
	let groupRef = firebase.database().ref('remoteAccess').child(removeStudent);
	await groupRef.remove();
};

// Sign user Out

export const logout = async () => {
	await firebase.auth().signOut();
};

// Email Login
export const sendFirstTimeLoginEmail = async (uid: string, email: string) => {
	let firstTimeActionCodeSettings = {
		url: `https://app.mussila.com/first_time/${email}/${uid}`,
		handleCodeInApp: true,
	};
	return await firebase.auth().sendSignInLinkToEmail(email, firstTimeActionCodeSettings);
};

// SaaS

export interface ICheckOutSession {
	line_items?: any[];
	priceKey?: string;
	allow_promotion_codes: boolean;
	success_url: string;
	cancel_url: string;
	anon?: boolean;
	mode?: string;
}

export const createCheckOutSession = async ({
	customerType,
	uid,
	priceKey,
	success_url = '/settings/account/subscription/success',
	cancel_url = '/admin/?menu=seats',
	anon,
	mode = 'subscription',
	quantity,
}: {
	customerType: 'school' | 'family';
	uid: string;
	priceKey: string;
	success_url?: string;
	cancel_url?: string;
	anon?: boolean;
	mode?: string;
	quantity?: number;
}) => {
	// Handle successful subscription
	const checkoutData: ICheckOutSession = {
		allow_promotion_codes: true,
		success_url: window.location.origin + success_url,
		cancel_url: window.location.origin + cancel_url,
		mode: mode,
	};

	if (customerType === 'family') {
		checkoutData.line_items = [
			{
				price: priceKey,
				quantity: 1
			}
		];
	}

	if (customerType === 'school') {
		checkoutData.line_items = [
			{
				price: priceKey,
				adjustable_quantity: {
					enabled: true,
					minimum: 25,
					maximum: 1000,
				},
				quantity: quantity,
			},
		];
	}

	if (anon) {
		checkoutData.anon = true;
	}

	const sessionRef = await firebase
		.firestore()
		.collection('customers')
		.doc(uid)
		.collection('checkout_sessions')
		.add(checkoutData);
	// Wait for the CheckoutSession to get attached by the extension
	sessionRef.onSnapshot(async (snap: any) => {
		const { sessionId } = snap.data();
		if (sessionId) {
			// We have a session, let's redirect to Checkout
			// Init Stripe
			const stripe = await getStripe();
			stripe.redirectToCheckout({ sessionId });
		}
	});
};

export const getStripeCustomer = async (uid: string) => {
	const customerRef = firebase.firestore().collection('customers').doc(uid);
	const customer = await customerRef.get();
	return customer.data();
};

export const createStripeCustomer = async (uid: string, email: string) => {
	const createNewCustomer = await callCloudFunction(stripeTest ? 'createStripeCustomerTest' : 'createStripeCustomer');
	await createNewCustomer({ uid: uid, email: email }).catch((e) => console.error(e));
};

export const createStripeCustomerIfNone = async (uid: string, email: string) => {
	const sessionRef = firebase.firestore().collection('customers').doc(uid);
	const doc = await sessionRef.get();
	if (!doc.exists) {
		const createNewCustomer = await callCloudFunction(stripeTest ? 'createStripeCustomerTest' : 'createStripeCustomer');
		await createNewCustomer({ uid: uid, email: email }).catch((e) => console.error(e));
	}
};

export const createAnonStripeCustomer = async (uid: string, email: string) => {
	const sessionRef = firebase.firestore().collection('customers').doc(uid);
	const doc = await sessionRef.get();
	if (!doc.exists) {
		const createNewCustomer = await callCloudFunction(stripeTest ? 'createStripeCustomerTest' : 'createStripeCustomer');
		await createNewCustomer({ uid: uid, email: email }).catch((e) => console.error(e));
	}
};

export const getLastPayment = async (uid: string) => {
	const paymentRef = await firebase
		.firestore()
		.collection('customers')
		.doc(uid)
		.collection('payments')
		.orderBy('created', 'desc')
		.limit(1)
		.get();
	if (!paymentRef.empty) {
		const paymentID = paymentRef.docs[0].id;
		const payment = await paymentRef.docs[0].data();
		return { id: paymentID, payment };
	}
	return null;
};

export const getGiftCardByPaymentID = async (uid: string, paymentID: string) => {
	const giftcardRef = await firebase
		.firestore()
		.collection('customers')
		.doc(uid)
		.collection('giftcards')
		.where('paymentID', '==', paymentID)
		.get();
	if (!giftcardRef.empty) {
		const giftcard = await giftcardRef.docs[0].data();
		return giftcard;
	}
	return null;
};

export const studentLastPlayed = async (userID: string) => {
	const lastSaveTimeMusic = await (
		await firebase
			.database()
			.ref('users-oldPlayers')
			.child(userID)
			.orderByChild('lastSaveTime')
			.limitToFirst(1)
			.once('value')
	).val();
	const lastSaveTimeWP = await (
		await firebase
			.database()
			.ref('users-oldPlayers-wordplay')
			.child(userID)
			.orderByChild('lastSaveTime')
			.limitToFirst(1)
			.once('value')
	).val();
	const mostRecentPlayTimeMusic =
		lastSaveTimeMusic && lastSaveTimeMusic[Object.keys(lastSaveTimeMusic)[0]].lastSaveTime;
	const mostRecentPlayTimeWP = lastSaveTimeWP && lastSaveTimeWP[Object.keys(lastSaveTimeWP)[0]].lastSaveTime;
	if (!mostRecentPlayTimeMusic && !mostRecentPlayTimeWP) {
		return null;
	}
	if (mostRecentPlayTimeMusic && !mostRecentPlayTimeWP) {
		return mostRecentPlayTimeMusic;
	}
	if (!mostRecentPlayTimeMusic && mostRecentPlayTimeWP) {
		return mostRecentPlayTimeWP;
	}
	return new Date(mostRecentPlayTimeMusic) > new Date(mostRecentPlayTimeWP)
		? mostRecentPlayTimeMusic
		: mostRecentPlayTimeWP;
};

export const getDistrictName = async (districtId: string) => {
	const districtNameRef = firebase.database().ref('users').child(districtId).child('district');
	return await districtNameRef.once('value').then((snapshot) => snapshot.val());
}