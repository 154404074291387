import LoaderSpinner from '../components/LoaderSpinner';

const Loading = () => {
	return (
		<div className='bg-white flex min-h-screen place-content-center place-items-center'>
			<LoaderSpinner loading={true} />
		</div>
	);
};

export default Loading;
