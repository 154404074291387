import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/navigation/PrivateRoute';
import { AuthProvider } from './libs/Authentication';
import { LocalizationProvider } from './libs/Localization';
import Loading from './pages/Loading';

// Code Splitting
const Wrapper = lazy(() => import('./pages/Wrapper'));
const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const Error = lazy(() => import('./pages/Error'));
const Account = lazy(() => import('./pages/schools/Account'));
const Group = lazy(() => import('./pages/schools/Group'));
const Student = lazy(() => import('./pages/schools/Student'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const Profile = lazy(() => import('./pages/Profile'));
const FirstPassword = lazy(() => import('./pages/auth/FirstPassword'));
const GiftCard = lazy(() => import('./pages/purchase/GiftCard'));
const GiftCardSuccess = lazy(() => import('./pages/purchase/GiftCardSuccess'));
const GiftCardCanceled = lazy(() => import('./pages/purchase/GiftCardCanceled'));
const SchoolsPurchaseSuccess = lazy(() => import('./pages/purchase/SchoolsPurchaseSuccess'));
const Skolon = lazy(() => import('./pages/skolon/Skolon'));
const SkolonAccount = lazy(() => import('./pages/skolon/SkolonAccount'));
const SkolonGroup = lazy(() => import('./pages/skolon/SkolonGroup'));
const SkolonStudent = lazy(() => import('./pages/skolon/SkolonStudent'));
const Purchase = lazy(() => import('./pages/purchase/Purchase'));
const FamilyPurchaseSuccess = lazy(() => import('./pages/purchase/FamilyPurchaseSuccess'));
const Family = lazy(() => import('./pages/Family'));
const Redirect = lazy(() => import('./pages/Redirect'));
const TeacherInvite = lazy(() => import('./pages/auth/TeacherInvite'));
const BuddyPhones = lazy(() => import('./pages/promo/BuddyPhones'));
const Admin = lazy(() => import('./pages/schools/Admin'));
const Library = lazy(() => import('./pages/auth/Library'));
const District = lazy(() => import('./pages/schools/District'));
const SkolonRedirect = lazy(() => import('./pages/skolon/SkolonRedirect'));
const Calendar = lazy(() => import('./pages/games/Calendar'));
const CalendarISL = lazy(() => import('./pages/games/CalendarISL'));

function App() {
	return (
		<Router>
			<LocalizationProvider>
				<AuthProvider>
					<Suspense fallback={<Loading />}>
						<Routes>
							<Route path='/calendar' element={<Calendar />} />
							<Route path='/dagatal' element={<CalendarISL />} />
							<Route path='/login' element={<Login />} />
							<Route path='/giftcard' element={<GiftCard />} />
							<Route path='/giftcard/success' element={<GiftCardSuccess />} />
							<Route path='/giftcard/canceled' element={<GiftCardCanceled />} />
							<Route path='/giftcard/:claimGive' element={<GiftCard />} />
							<Route path='/first_time/:email/:id' element={<FirstPassword />} />
							<Route path='/library/:libraryId' element={<Library />} />
							<Route path='/forgot' element={<ForgotPassword />} />
							<Route path='/register' element={<Register />} />
							<Route path='/teacherInvite/*' element={<TeacherInvite />} />
							<Route path='/invite/*' element={<TeacherInvite />} />
							<Route path='/register/r/:referredBy' element={<Register />} />
							<Route path='/purchase' element={<Purchase />} />
							<Route path='/purchase/success' element={<FamilyPurchaseSuccess />} />
							<Route path='/' element={<PrivateRoute />}>
								<Route path='/' element={<Wrapper />} />
							</Route>
							<Route path='/skolon/login' element={<SkolonRedirect />} />
							<Route path='/skolon' element={<PrivateRoute />}>
								<Route path='/skolon' element={<Skolon />} />
							</Route>
							<Route path='/skolon/:id' element={<PrivateRoute />}>
								<Route path='/skolon/:id' element={<SkolonAccount />} />
							</Route>
							<Route path='/skolon/:schoolID/group/:groupUUID' element={<PrivateRoute />}>
								<Route path='/skolon/:schoolID/group/:groupUUID' element={<SkolonGroup />} />
							</Route>
							<Route path='/skolon/:schoolID/group/:groupUUID/student/:studentID' element={<PrivateRoute />}>
								<Route path='/skolon/:schoolID/group/:groupUUID/student/:studentID' element={<SkolonStudent />} />
							</Route>
							<Route path='/account/:id' element={<PrivateRoute />}>
								<Route path='/account/:id' element={<Account />} />
							</Route>
							<Route path='/account/' element={<PrivateRoute />}>
								<Route path='/account/' element={<Account />} />
							</Route>
							<Route path='/family' element={<PrivateRoute />}>
								<Route path='/family' element={<Family />} />
							</Route>
							<Route path='/family/:studentID' element={<PrivateRoute />}>
								<Route path='/family/:studentID' element={<Student />} />
							</Route>
							<Route path='/settings/account' element={<PrivateRoute />}>
								<Route path='/settings/account' element={<Profile />} />
							</Route>
							<Route path='/admin' element={<PrivateRoute />}>
								<Route path='/admin' element={<Admin />} />
							</Route>
							<Route path='/district' element={<PrivateRoute />}>
								<Route path='/district' element={<District />} />
							</Route>
							<Route path='/settings/account/subscription/success' element={<PrivateRoute />}>
								<Route path='/settings/account/subscription/success' element={<SchoolsPurchaseSuccess />} />
							</Route>
							<Route path='/account/:accountID/group/:groupID' element={<PrivateRoute />}>
								<Route path='/account/:accountID/group/:groupID' element={<Group />} />
							</Route>
							<Route path='/account/:accountID/group/:groupID/student/:studentID' element={<PrivateRoute />}>
								<Route path='/account/:accountID/group/:groupID/student/:studentID' element={<Student />} />
							</Route>
							<Route path='/buddyphones' element={<BuddyPhones />} />
							<Route path='/admin.html' element={<Navigate to='/' />} />
							<Route path='/r' element={<Redirect />} />
							<Route path='*' element={<Error />} />
						</Routes>
					</Suspense>
				</AuthProvider>
			</LocalizationProvider>
		</Router>
	);
}

export default App;
