import Loader from 'react-loader-spinner';
import { loaderInterface } from '../types/misc';

const LoaderSpinner = ({ loading, text, size }: loaderInterface) => {
	return (
		<div className={`flex flex-col place-items-center ${loading ? null : 'hidden'}`}>
			{text ? <div className='prose pb-4'>{text}</div> : null}
			<Loader type='TailSpin' color='#00BFFF' height={size ? size : 50} width={size ? size : 50} />
		</div>
	);
};

export default LoaderSpinner;
