import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../libs/Authentication';
import { localize as l } from '../../libs/Localization';
import { logout } from '../../services/db';

const PrivateRoute = () => {
	const auth = useAuth();
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (auth.session && auth.type === null) {
			logout();
			return;
		}
		if (auth.type === null) {
			logout();
			setError(l['Not a registered school / family account.']);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return auth.session ? <Outlet /> : <Navigate to='/login' state={{ error: error }} />
};

export default PrivateRoute;
