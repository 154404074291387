import React, { useContext, useEffect, useState } from 'react';
import { User } from '@firebase/auth-types';
import { authentication } from '../services/firebase';
import useLocalStorage from '../hooks/useLocalStorage';
import { getUserType } from '../services/db';

interface AuthInterface {
	session: User | null;
	type: string | null;
	setUserType: null | ((type: string | null) => void);
}

const AuthContext = React.createContext<AuthInterface>({ session: null, type: null, setUserType: null });

export const useAuth = () => {
	return useContext(AuthContext);
};

export interface ICheckAuthStatus {
	session: User | null;
	userType: string | null;
	setUserType: (value: string | null) => void;
	setSession: (session: User | null) => void;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthProvider: React.FC = ({ children }) => {
	const [session, setSession] = useLocalStorage<User | null>('session', null);
	const [userType, setUserType] = useLocalStorage<string | null>('userType', null);
	// const [userType, setUserType] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	useEffect(() => {
		const unsubscribe = authentication.onAuthStateChanged(async (session) => {
			if (session) {
				const userIsType = await getUserType(session);
				if (userType !== userIsType) {
					setUserType(userIsType);
				}
				setSession(session);
			} else {
				// console.log('no session for some reason?');
				setUserType(null);
				setSession(null);
			}
			setLoading(false);
		});
		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AuthContext.Provider value={{ session: session, type: userType, setUserType: setUserType }}>{!loading && children}</AuthContext.Provider>
	);
};
