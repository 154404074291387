import React, { useContext, useEffect, useState } from 'react'
import LocalizedStrings from 'react-localization';
import useLocalStorage from '../hooks/useLocalStorage';
import translations from './translations.json';

export const localize = new LocalizedStrings(translations);

interface LocalizationInterface {
	localization: string | null;
}

const LocalizationContext = React.createContext<LocalizationInterface>({ localization: null });

export const useLocalization = () => {
	return useContext(LocalizationContext);
};

export const LocalizationProvider: React.FC = ({ children }) => {
	const [localization, setLocalization] = useLocalStorage<string>('localization', 'English');
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setLocalization(localization);
		localize.setLanguage(localization);
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LocalizationContext.Provider value={{ localization }}>{!loading && children}</LocalizationContext.Provider>
	);
};