import { loadStripe } from '@stripe/stripe-js';

export const stripeTest = process.env.REACT_APP_TESTING === 'TRUE' ?? false; //! Make sure its false on production release!

const key = stripeTest ? process.env.REACT_APP_TEST_STRIPE_KEY : process.env.REACT_APP_STRIPE_KEY;
if (stripeTest) {	
	console.warn("!!! Stripe connected to test environment !!!");
}

let stripePromise: any;
export const getStripe = async () => {
	if (!stripePromise) {
		stripePromise = await loadStripe(key!).catch((e) => {
			console.error(e);
		});
	}
	return stripePromise;
};